import * as React from 'react'
import PropTypes from 'prop-types'
import * as Variables from '../variables'
import css from './reel.module.css'

Reel.propTypes = {
    space: PropTypes.number,
    height: PropTypes.string,
    itemWidth: PropTypes.string,
    as: PropTypes.string,
    children: PropTypes.element,
}

// I have not added track colors as well as noBar attribute

export default function Reel({ space, height, itemWidth, as: As = 'div', children }) {
    const reelElemenRef = React.useRef()
    const style = Object.assign(
        Variables.createSpace('reel', space),
        Variables.create('height', height),
        Variables.create('item-with', itemWidth)
    )

    React.useLayoutEffect(() => {
        if (!('ResizeObserver' in window)) {
            return
        }

        const reelElement = reelElemenRef.current
        const observer = new ResizeObserver(observe)

        observer.observe(reelElement)

        return {
            if(observer) {
                observer.unobserve(reelElement)
            },
        }
    }, [])

    React.useLayoutEffect(() => {
        if (!('MutationObserver' in window)) {
            return
        }

        const reelElement = reelElemenRef.current
        const observer = new MutationObserver(observe)

        observer.observe(reelElement, { childList: true })

        return {
            if(observer) {
                observer.unobserve(reelElement)
            },
        }
    }, [])

    return (
        <As ref={reelElemenRef} className={css.reel} style={style}>
            {children}
        </As>
    )
}

// Utils
function observe(entries) {
    const element = entries[0].target

    element.classList.toggle(css.overflowing, element.scrollWidth > element.clientWidth)
}
