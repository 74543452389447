import React from 'react'
import Icon from './Icon'
import COLOR from 'constants/colors'

export default function Gear({ color = COLOR, ...props }) {
    return (
        <Icon {...props}>
            <path
                d="m14.31.224c-1.525-.299-3.095-.299-4.62 0l-.485 2.857c-1.313.411-2.519 1.107-3.532 2.039l-2.716-1.009c-1.022 1.172-1.806 2.531-2.31 4.002l2.231 1.848c-.3 1.343-.3 2.735 0 4.078l-2.231 1.848c.504 1.471 1.288 2.83 2.31 4.002l2.716-1.009c1.013.932 2.219 1.628 3.532 2.039l.485 2.857c1.525.299 3.095.299 4.62 0l.485-2.857c1.313-.411 2.519-1.107 3.532-2.039l2.716 1.009c1.022-1.172 1.806-2.531 2.31-4.002l-2.231-1.848c.3-1.343.3-2.735 0-4.078l2.231-1.848c-.504-1.471-1.288-2.83-2.31-4.002l-2.716 1.009c-1.013-.932-2.219-1.628-3.532-2.039l-.485-2.857zm-2.31 6.861c2.713 0 4.915 2.202 4.915 4.915s-2.202 4.915-4.915 4.915-4.915-2.202-4.915-4.915 2.202-4.915 4.915-4.915z"
                fill={color}
            />
        </Icon>
    )
}
