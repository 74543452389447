import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import KIND, { ALL as KINDS } from './kinds'
import css from './Button.module.css'

const LEFT = 'LEFT'
const RIGHT = 'RIGHT'

Button.propTypes = {
    children: PropTypes.node,
    active: PropTypes.bool,
    shadow: PropTypes.bool,
    large: PropTypes.bool,
    kind: PropTypes.oneOf(Array.from(KINDS)),
    chevron: PropTypes.oneOf([LEFT, RIGHT, true]),
    title: PropTypes.string,
    className: PropTypes.string,
}

function Button({
    kind = KIND,
    active = false,
    shadow = false,
    large = false,
    chevron,
    title = '',
    children,
    ...props
}) {
    const className = clsx(props.className, {
        [css[kind]]: true,
        [css.Active]: active,
        [css.Shadow]: shadow,
        [css.Large]: large,
        [css.ChevronLeft]: chevron === LEFT,
        [css.ChevronRight]: chevron === RIGHT,
        [css.Chevron]: chevron === true,
    })

    return (
        <button type="button" title={title} {...props} className={className}>
            {children}
        </button>
    )
}

export default React.memo(Button)
