import { ItemSet, Item } from 'components/sponsor'

export default function SponsorSet({ value }) {
    return (
        <ItemSet>
            {value.filter(isNotBroken).map(({ sponsor }) => {
                const { id, data } = sponsor

                return <Item key={id} title={data.name} src={data['image-229']} url={data.url} />
            })}
        </ItemSet>
    )
}

// Utils
function isNotBroken({ sponsor }) {
    return sponsor.isBroken === false
}
