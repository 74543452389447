import React from 'react'
import { useIntl } from 'react-intl'
import src from './hot-zone-report.svg'

export default function Advisory({ width = 24, height = 24, ...props }) {
    const intl = useIntl()
    const alt = intl.formatMessage({
        defaultMessage: 'Avalanche Advisory icon',
    })

    return <img width={width} height={height} alt={alt} {...props} src={src} />
}
