import React from 'react'
import styles from './Feed.module.css'

export default function EntrySet({ children, ...props }) {
    return (
        <div {...props} className={styles.EntrySet} data-cy="entry-set">
            {children}
        </div>
    )
}
