import * as React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'
import { useRouter } from 'next/router'
import * as Router from 'hooks/router'
import * as News from '@avcan/prismic/clients/news'
import * as Events from '@avcan/prismic/clients/events'
import * as Blog from '@avcan/prismic/clients/blog'
import * as Types from '@avcan/prismic/types'

Provider.propTypes = {
    paramsSchema: PropTypes.object.isRequired,
    type: PropTypes.oneOf([Types.BLOG, Types.EVENT, Types.NEWS]).isRequired,
    data: PropTypes.shape({
        posts: PropTypes.arrayOf(PropTypes.object).isRequired,
        page: PropTypes.number.isRequired,
        totalPages: PropTypes.number.isRequired,
    }).isRequired,
    children: PropTypes.element,
}

export function Provider({ paramsSchema, type, data: fallbackData, children }) {
    const { locale, isReady } = useRouter()
    const params = Router.useParams(paramsSchema)
    const fetcher = fetchers.get(type)
    const { data, error } = useSWR(
        isReady ? [type, locale, ...Object.values(params.value).flat()] : null,
        () => fetcher(params.value, locale),
        {
            revalidateOnMount: true,
            fallbackData: params.isEmpty() ? fallbackData : undefined,
        }
    )
    const value = React.useMemo(
        () => ({
            type,
            ...data,
            error,
            params: params.value,
            onParamsChange(newParams) {
                params.merge(newParams)
            },
            onPageChange(page) {
                params.merge({ page })
            },
        }),
        [type, data, error, params]
    )

    return <context.Provider value={value}>{children}</context.Provider>
}

export function useContext() {
    return React.useContext(context)
}

const context = React.createContext()

const fetchers = new Map([
    [Types.NEWS, News.getList],
    [Types.EVENT, Events.getList],
    [Types.BLOG, Blog.getList],
])
