import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as Variables from '../variables'
import css from './cluster.module.css'

const positions = [
    'flex-start',
    'center',
    'flex-end',
    'space-between',
    'space-around',
    'space-evenly',
]

Cluster.propTypes = {
    justify: PropTypes.oneOf(positions),
    align: PropTypes.oneOf(positions),
    space: PropTypes.number,
    as: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
}

export default function Cluster({ justify, align, space, as: As = 'div', className, children }) {
    const style = Object.assign(
        Variables.create('justify', justify),
        Variables.create('align', align),
        Variables.createSpace('cluster', space)
    )

    return (
        <As className={clsx(css.cluster, className)} style={style}>
            {children}
        </As>
    )
}
