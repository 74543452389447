import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/button'
import { noop } from '@avcan/utils/function'
import styles from './Pagination.module.css'

Segment.propTypes = {
    page: PropTypes.number.isRequired,
    onActivate: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    children: PropTypes.element,
}

export default function Segment({ page, disabled, onActivate = noop, children = page }) {
    function handleClick() {
        onActivate(page)
    }

    return (
        <Button onClick={handleClick} disabled={disabled} className={styles.Segment}>
            {children}
        </Button>
    )
}
