import * as React from 'react'
import { useRouter } from 'next/router'
import * as Hooks from 'hooks/params'
import * as ParamsUtils from '@avcan/utils/params'

export function useURL() {
    const { asPath } = useRouter()

    return React.useMemo(() => new URL(asPath, process.env.NEXT_PUBLIC_SITE_ORIGIN_URL), [asPath])
}

export function useParams(schema, options) {
    const { query, asPath, push, isReady } = useRouter()
    const utils = Hooks.useParamsUtils(schema)
    const params = isReady ? query : ParamsUtils.fromString(asPath.split('?')[1])
    const value = React.useMemo(() => utils.parse(params), [params, utils])
    const optionsRef = React.useRef(Object.assign({}, OPTIONS, options))

    return React.useMemo(() => {
        return {
            value,
            isEmpty() {
                return utils.isEmpty(value)
            },
            merge(params) {
                const query = utils.format({ ...value, ...params })

                push({ query }, undefined, optionsRef.current)
            },
        }
    }, [push, value, utils])
}

// Utils & constants
const OPTIONS = { scroll: true, shallow: true }
