import { Heading } from 'components/page'
import { Splash } from 'layouts/feed'
import { StructuredText } from 'prismic/components/base'
import Shim from 'components/Shim'

export default function FeedSplash({ value }) {
    const { header, hash, documents } = value

    return (
        <Splash documents={documents}>
            <Shim right>
                <Heading as="div" hash={hash}>
                    <StructuredText value={header} />
                </Heading>
            </Shim>
        </Splash>
    )
}
