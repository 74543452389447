export const USERNAME = 'avalanchecanada'
export const ACCESS_TOKEN =
    'pk.eyJ1IjoiYXZhbGFuY2hlY2FuYWRhIiwiYSI6ImNqd2dvZmUxdzE4ZWg0M2tkaXpuNG95aTQifQ.pBLM87fE3sIxRJqJT7Bf7g'

export const API = 'https://api.mapbox.com'

export const STYLE_IDS = {
    // TODO: remove the hardcoded styles ids once the build is updated
    default: process.env.NEXT_PUBLIC_MAPBOX_STYLE_ID || 'ckuk675w6gwfk17pjrqae4tjy',
    ates: process.env.NEXT_PUBLIC_MAPBOX_STYLE_ID_ATES || 'cklsnt2tb1n6l17o28ctk1712',
    grayscale: process.env.NEXT_PUBLIC_MAPBOX_STYLE_ID_GRAYSCALE || 'clf069jjs001701qkg9vm52x0',
}

const STYLE_ROOT = 'mapbox://styles/' + USERNAME + '/'

export const STYLES = {
    default: STYLE_ROOT + STYLE_IDS.default,
    ates: STYLE_ROOT + STYLE_IDS.ates,
    grayscale: STYLE_ROOT + STYLE_IDS.grayscale,
}

const TILESET_ROOT = 'mapbox://' + USERNAME + '.'

export const TILESETS = {
    closureZones: TILESET_ROOT + 'ckukhj6gpaetl27pe2gfkc2dq-5cgrw',
}
