import PropTypes from 'prop-types'
import clsx from 'clsx'
import Search from './Search'
import css from './Form.module.css'

export { Search }

Form.propTypes = {
    children: PropTypes.node.isRequired,
    style: PropTypes.object,
}

export function Form({ children, style }) {
    return (
        <form className={css.Form} style={style}>
            {children}
        </form>
    )
}

Fieldset.propTypes = {
    children: PropTypes.node.isRequired,
}

// TODO Remove that as default export. It should be a named export like below...
export default function Fieldset({ children }) {
    return <fieldset className={css.Fieldset}>{children}</fieldset>
}

export { Fieldset }

Legend.propTypes = {
    children: PropTypes.node.isRequired,
}

export function Legend({ children }) {
    return <legend className={css.Legend}>{children}</legend>
}

Control.propTypes = {
    children: PropTypes.node.isRequired,
    horizontal: PropTypes.bool,
    bordered: PropTypes.bool,
}

export function Control({ children, horizontal, bordered, ...props }) {
    const className = clsx({
        [css.Control]: !horizontal,
        [css['Control--Horizontal']]: horizontal,
        [css.Border]: bordered,
    })

    return (
        <div className={className} {...props}>
            {children}
        </div>
    )
}

ControlSet.propTypes = {
    children: PropTypes.node.isRequired,
    horizontal: PropTypes.bool,
}

export function ControlSet({ horizontal, children }) {
    const className = horizontal ? 'ControlSet--Horizontal' : 'ControlSet'

    return (
        <div className={css[className]} data-cy="filters">
            {children}
        </div>
    )
}
