import PropTypes from 'prop-types'
import clsx from 'clsx'
import styles from './Tag.module.css'

TagSet.propTypes = {
    children: PropTypes.node.isRequired,
}

export function TagSet({ children }) {
    return <ul className={styles.Set}>{children}</ul>
}

Tag.propTypes = {
    children: PropTypes.node.isRequired,
    as: PropTypes.oneOf([PropTypes.string, PropTypes.object]),
    className: PropTypes.string,
}

export function Tag({ as: As = 'li', className, children, ...props }) {
    return (
        <As className={clsx(styles.Item, className)} {...props}>
            {children}
        </As>
    )
}
