import PropTypes from 'prop-types'
import clsx from 'clsx'
import styles from './Controls.module.css'

Input.propTypes = {
    className: PropTypes.string,
}

export default function Input({ className, ...props }) {
    return <input {...props} className={clsx(styles.Input, className)} />
}
