export const DATE = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
}

export const DATE_COMPACT = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
}

export const TIME = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
}

export const DATETIME = { ...DATE, ...TIME }

export const MONTH = {
    month: 'long',
}

export const PERCENT = {
    style: 'percent',
}
