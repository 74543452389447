import PropTypes from 'prop-types'
import * as Variables from '../variables'
import css from './frame.module.css'

Frame.propTypes = {
    ratio: PropTypes.string,
    as: PropTypes.string,
    children: PropTypes.element,
}

export default function Frame({ ratio = '', as: As = 'div', children }) {
    const [denominator, numerator] = ratio.split(':')
    const style = Object.assign(
        Variables.create('denominator', denominator),
        Variables.create('numerator', numerator)
    )

    return (
        <As className={css.frame} style={style}>
            {children}
        </As>
    )
}
