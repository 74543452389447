import PropTypes from 'prop-types'
import { Credit } from 'components/misc'
import { Ribbon } from 'components/misc'
import { useWindowSize } from 'hooks'
import Sponsor from 'layouts/sponsor'
import styles from './Page.module.css'

// TODO Perhaps remove that component
Content.propTypes = {
    children: PropTypes.node.isRequired,
}

export function Content({ children, ...props }) {
    return (
        <div {...props} className={styles.Content}>
            {children}
        </div>
    )
}

Section.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.node.isRequired,
    ribbon: PropTypes.string,
    hash: PropTypes.string,
    headline: PropTypes.string,
    level: PropTypes.oneOf([1, 2, 3, 4, 5]),
}

// TODO: review any instances where this component is used and switch it for a scrollable forecast.
export function Section({
    headline,
    children,
    // TODO Review that prop
    level = 1,
    // TODO Review if being used!
    ribbon,
    hash,
    title,
}) {
    const as = `h${level + 1}`

    return (
        <section className={styles.Section}>
            {ribbon ? (
                <Ribbon caption={ribbon}>{title}</Ribbon>
            ) : (
                <header>
                    <Heading as={as} hash={hash}>
                        {title}
                    </Heading>
                    {headline && <Headline>{headline}</Headline>}
                </header>
            )}
            {children}
        </section>
    )
}

export function Heading({ as: As = 'h2', hash, children }) {
    const href = '#' + hash

    if (hash) {
        children = <a href={href}>{children}</a>
    }

    return (
        <As id={hash} className={styles.Heading}>
            {children}
        </As>
    )
}

Main.propTypes = {
    children: PropTypes.node.isRequired,
}

export function Main({ children, ...props }) {
    return (
        <main className={styles.Main} {...props}>
            {children}
        </main>
    )
}

Banner.propTypes = {
    url: PropTypes.string.isRequired,
    copyright: PropTypes.string,
    children: PropTypes.node,
}

export function Banner({ url, copyright, children }) {
    const { width } = useWindowSize()
    const style = {
        backgroundImage: `url("${url}")`,
    }

    return (
        <div className={styles.Banner} style={style}>
            {copyright && <Credit>{copyright}</Credit>}
            {children}
        </div>
    )
}

Article.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node.isRequired,
}

export function Article({ title, children }) {
    return (
        <article className={styles.Article}>
            {title && <h2>{title}</h2>}
            {children}
        </article>
    )
}

Header.propTypes = {
    title: PropTypes.node.isRequired,
    children: PropTypes.node,
}

export function Header({ title, payload, children }) {
    return (
        <header className={styles.Header}>
            {title && <h1>{title}</h1>}
            {children}
            <Sponsor payload={payload} />
        </header>
    )
}

export function Headline({ children, ...props }) {
    return (
        <em {...props} className={styles.Headline}>
            {children}
        </em>
    )
}

Aside.propTypes = {
    children: PropTypes.node.isRequired,
}

export function Aside({ children, ...props }) {
    return (
        <aside className={styles.Aside} {...props}>
            {children}
        </aside>
    )
}

// List
// TODO Could be moved to another module
List.propTypes = {
    children: PropTypes.node,
    column: PropTypes.number,
    data: PropTypes.array,
    renderItem: PropTypes.func,
}

export function List({ data, renderItem, children, column }) {
    const style = typeof column === 'number' ? { columnCount: column } : null

    return (
        <ul style={style} className={styles.List}>
            {children || data.map(renderItem)}
        </ul>
    )
}

ListItem.propTypes = {
    children: PropTypes.node.isRequired,
}

export function ListItem({ children }) {
    return <li>{children}</li>
}
