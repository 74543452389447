import { create } from 'zustand'

const MixpanelStore = create(set => ({
    isLoaded: false,
    setIsLoaded: isLoaded => {
        set({ isLoaded })
    },
}))

export const useIsLoaded = () => MixpanelStore(state => state.isLoaded)
export const useSetIsLoaded = () => MixpanelStore(state => state.setIsLoaded)
