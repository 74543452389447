import Base from 'react-image-gallery'
import * as Icons from 'components/icons'
import { WHITE } from 'constants/colors'

import 'react-image-gallery/styles/css/image-gallery.css'

export default function Gallery(props) {
    return <Base {...props} {...buttons} />
}

// Utils
function Button(props) {
    return <button type="button" {...props} />
}

const buttons = {
    renderLeftNav(onClick, disabled) {
        return (
            <Button
                className="image-gallery-icon image-gallery-left-nav"
                onClick={onClick}
                disabled={disabled}>
                <Icons.ChevronLeft height={36} width={36} color={WHITE} />
            </Button>
        )
    },
    renderRightNav(onClick, disabled) {
        return (
            <Button
                className="image-gallery-icon image-gallery-right-nav"
                onClick={onClick}
                disabled={disabled}>
                <Icons.ChevronRight height={36} width={36} color={WHITE} />
            </Button>
        )
    },
    renderPlayPauseButton(onClick, isPlaying) {
        return (
            <Button className="image-gallery-icon image-gallery-play-button" onClick={onClick}>
                {isPlaying ? <Icons.Pause inverse /> : <Icons.Play inverse />}
            </Button>
        )
    },
    // We have removed this button as the react-image-gallery plugin was not displaying
    // the image correctly in full screen. We are moving towards Photoswipe anyways
    // See PR: https://github.com/avalanche-canada/AvCan-Clients/pull/386
    renderFullscreenButton(_onClick, _isFullscreen) {
        return null
    },
}
