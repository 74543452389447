import React from 'react'
import Icon from './Icon'
import COLOR from 'constants/colors'

export default function Download({ color = COLOR, ...props }) {
    return (
        <Icon viewBox="0 0 24 18" {...props}>
            <path d="M0 0h24v24H0z" fill="none" />
            <path
                d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z"
                fill={color}
            />
        </Icon>
    )
}
