import * as React from 'react'
import { useRouter } from 'next/router'
import useSWR from 'swr'
import * as Config from './config'
import SpawClient from '@avcan/utils/api/clients/spaws'
import * as Products from '@avcan/constants/products'
import useSWRImmutable from 'swr/immutable'

export const client = new SpawClient(Config.api, Config.key)

export function useSpaw(id) {
    const { locale } = useRouter()
    const key = ['spaw', id, locale]

    return useSWR(key, () => client.product(id, locale))
}

export function useSpaws() {
    const { locale } = useRouter()
    const key = ['spaw', locale]

    return useSWR(key, () => client.products(locale))
}

// TODO: This is a temporary solution to fetch the SPAW area via getStaticProps. We'll want to replace this with SWR
export function getSPAWArea(locale) {
    return client.area(locale)
}
