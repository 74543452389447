import * as React from 'react'
import PropTypes from 'prop-types'
import useSWR from 'swr'
import { useIntl } from 'react-intl'
import { noop } from '@avcan/utils/function'
import { Input } from 'components/controls'
import { Place } from 'components/icons'
import { place as request } from 'services/mapbox/requests'
import { OptionSet, Option, Dropdown } from 'components/controls/options'
import { Close } from 'components/button'
import { useBoolean } from 'hooks'
import * as Async from 'contexts/async'
import fetch from '@avcan/utils/fetch'
import { PRIMARY } from 'constants/colors'
import styles from './Geocoder.module.css'

Geocoder.propTypes = {
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
}

export default function Geocoder({ value = '', onChange = noop, placeholder }) {
    const intl = useIntl()
    const [active, activate, deactivate] = useBoolean(false)
    const [term, setTerm] = React.useState(value || '')
    const swr = useSWR(term ? request(term) : null, fetch)
    const hasTerm = Boolean(term)
    function handleChange(event) {
        const { value } = event.target

        activate()
        setTerm(value)
    }
    function handleOptionClick(place) {
        deactivate()
        setTerm(place.text)
        onChange(place)
    }
    function handleClearClick() {
        deactivate()
        setTerm('')
        onChange(null)
    }

    placeholder =
        placeholder ||
        intl.formatMessage({
            description: 'Component Controls/Geocoder',
            defaultMessage: 'Search',
        })

    return (
        <div className={styles.Container}>
            <Place color={PRIMARY} />
            <Input
                type="text"
                placeholder={placeholder}
                className={styles.Input}
                value={term}
                onChange={handleChange}
                onFocus={activate}
                onBlur={deactivate}
            />
            <Async.Provider value={swr}>
                {hasTerm && (
                    <Async.Found>
                        <Close onClick={handleClearClick} />
                    </Async.Found>
                )}
                <Async.Found>
                    {data => {
                        if (!hasTerm || !active) {
                            return null
                        }

                        return (
                            <Dropdown>
                                <OptionSet onChange={handleOptionClick}>
                                    {data.features.map(place => (
                                        <Option key={place.id} value={place}>
                                            {place.place_name}
                                        </Option>
                                    ))}
                                </OptionSet>
                            </Dropdown>
                        )
                    }}
                </Async.Found>
            </Async.Provider>
        </div>
    )
}
