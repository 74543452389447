import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as Variables from '../variables'
import css from './bleeder.module.css'

Bleeder.propTypes = {
    as: PropTypes.string,
    children: PropTypes.element,
    full: PropTypes.bool,
    width: PropTypes.string,
    padding: PropTypes.number,
}

export default function Bleeder({
    as: As = 'div',
    full = false,
    width = null,
    padding = null,
    children,
}) {
    const style = {
        ...Variables.createSpace('bleeder-padding', padding),
        ...Variables.create('bleeder-width', width),
    }
    const className = clsx(css.bleeder, full && css.full)

    return (
        <As className={className} style={style}>
            {children}
        </As>
    )
}
