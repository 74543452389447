import { useMemo } from 'react'

import useSWR from 'swr'
import { useRouter } from 'next/router'
import { getAll } from '@avcan/prismic/clients/sponsors'

import { LOCALES } from '@avcan/constants/locales'
import {
    AVALANCHE_QUEBEC,
    KANANASKIS,
    PARKS_CANADA,
    PARKS_CANADA_BYK,
    PARKS_CANADA_GLACIER,
    PARKS_CANADA_JASPER,
    PARKS_CANADA_WATERTON,
} from '@avcan/constants/products/owners'

import Static from 'clients/static'

export function useSponsors() {
    const sponsors = {}
    const { locale } = useRouter()

    const metadataKey = ['metadata']
    const { data: metadata } = useSWR(metadataKey, () => Static.getSponsors())

    for (const locale of LOCALES) {
        const documentsKey = ['documents', locale]
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { data: documents } = useSWR(documentsKey, () => getAll(locale))

        Object.assign(sponsors, {
            [locale]:
                documents &&
                documents.map(({ uid, data }) => ({
                    uid,
                    name: data.name,
                    url: data.url,
                    logo: data['image-229'],
                })),
        })
    }

    const value = useMemo(
        () => ({
            sponsors: sponsors[locale],
            metadata: metadata,
        }),
        [locale, sponsors, metadata]
    )

    return value
}

export function useSponsor(forecast = null) {
    const { route, asPath } = useRouter()
    const { sponsors, metadata } = useSponsors()

    return useMemo(() => {
        if (!sponsors || !metadata) {
            return null
        }

        let uid
        if (forecast) {
            const owner = forecast.owner?.value
            switch (owner) {
                case AVALANCHE_QUEBEC:
                    uid = metadata.AvQForecast || 'avalanche-quebec'
                    break
                case KANANASKIS:
                    uid = 'kananaskis'
                    break
                case PARKS_CANADA:
                case PARKS_CANADA_BYK:
                case PARKS_CANADA_GLACIER:
                case PARKS_CANADA_JASPER:
                case PARKS_CANADA_WATERTON:
                    uid = 'parks-canada'
                    break
                default:
                    uid = metadata.Forecast || 'acf'
            }
        } else {
            const paths = [asPath, route]
            const hasSponsor = paths.some(path => SponsorNameByPath.has(path))

            if (!hasSponsor) {
                return null
            }

            const path = paths.find(path => SponsorNameByPath.get(path))
            const name = SponsorNameByPath.get(path)

            uid = metadata[name] || name
        }
        return sponsors.find(sponsor => sponsor.uid === uid) || null
    }, [asPath, metadata, route, sponsors, forecast])
}

// Constants
const SponsorNameByPath = new Map([
    ['/map/[[...path]]', 'Forecast'],
    // TODO Better deal with these repeated ones, but will eventually be handled inside Prismic
    ['/mountain-information-network', 'MIN'],
    ['/mountain-information-network/submissions', 'MIN'],
    ['/mountain-information-network/submissions/[id]', 'MIN'],
    ['/mountain-information-network/faq', 'MIN'],
    ['/mountain-information-network/submission-guidelines', 'MIN'],
    ['/mountain-information-network/submit', 'MIN'],
    ['/events/[uid]', 'EventPage'],
    ['/events', 'EventIndex'],
    ['/news/[uid]', 'NewsPage'],
    ['/news', 'NewsIndex'],
    ['/blogs/[uid]', 'BlogPage'],
    ['/blogs', 'BlogIndex'],
    ['/forecasts', 'Forecast'],
    // TODO Better deal with these repeated ones, but will eventually be handled inside Prismic
    ['/weather/forecast', 'Weather'],
    ['/weather/forecast/[date]', 'Weather'],
    ['/weather/forecast/12h-precipitation', 'Weather'],
    ['/weather/forecast/actual-temperatures', 'Weather'],
    ['/weather/forecast/hourly-precipitation', 'Weather'],
    ['/weather/forecast/other-maps', 'Weather'],
    ['/weather/forecast/radar', 'Weather'],
    ['/weather/forecast/satellite', 'Weather'],
    ['/weather/forecast/surface-maps', 'Weather'],
    ['/weather/forecast/temperatures', 'Weather'],
    ['/weather/forecast/warnings', 'Weather'],
    ['/weather/forecast/winds', 'Weather'],
    ['/weather/stations', 'Weather'],
    ['/weather/stations/[id]', 'Weather'],
    ['/weather/glossary', 'Weather'],
    ['/training', 'Training'],
    ['/training/courses', 'Training'],
    ['/training/providers', 'Training'],
    ['/training/companion-rescue', 'Training'],
    ['/instructing-ast', 'Training'],
    ['/youth', 'Youth'],
    ['/gear', 'Gear'],
    ['/about', 'About'],
])
