import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { IntlProvider } from 'react-intl'
import { noop } from '@avcan/utils/function'
import Messages from 'services/intl/messages'

Provider.propTypes = {
    children: PropTypes.element,
}

export function Provider({ children }) {
    const { locale } = useRouter()
    const messages = Messages.get(locale)

    return (
        <IntlProvider onError={noop} key={locale} locale={locale + '-CA'} messages={messages}>
            {children}
        </IntlProvider>
    )
}
