import { forType, OneLiner } from 'components/alert'
import { Link, StructuredText } from 'prismic/components/base'
import { useVisibility } from 'hooks/storage/session'
import { useHighlight } from 'hooks/useHighlight'

const ACCEPTABLE_LAYOUTS = ['website', 'both']

export default function Highlight() {
    const [visible, hide] = useVisibility('highlight')
    const { data, error } = useHighlight()

    if (!visible || !data || error) {
        return null
    }

    const { link, description, style, layout } = data.data

    const forDesktop = layout ? ACCEPTABLE_LAYOUTS.includes(layout) : true
    if (!forDesktop) {
        return null
    }

    const Alert = forType(style)
    const children = (
        <OneLiner>
            <StructuredText value={description} />
        </OneLiner>
    )

    return <Alert onDismiss={hide}>{link ? <Link {...link}>{children}</Link> : children}</Alert>
}
