import React from 'react'

import { FormattedMessage } from 'react-intl'
import Link from 'next/link'

import * as Alerts from 'components/alert'

export const NotProductionAlert = () => {
    if (process.env.NEXT_PUBLIC_SHOW_NOT_PRODUCTION_WARNING !== 'true') {
        return null
    }

    return (
        <Alerts.Info>
            <FormattedMessage
                description="Not Production Alert"
                defaultMessage="These are not real forecasts. This is a demonstration only. More information {link}."
                values={{
                    link: <Link href="/forecasts">here</Link>,
                }}
            />
        </Alerts.Info>
    )
}
