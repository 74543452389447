import * as React from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import * as URLUtils from 'utils/url'

import styles from './innerHTML.module.css'

InnerHTML.propTypes = {
    children: PropTypes.string,
    as: PropTypes.string,
}

export default function InnerHTML({
    children,
    as: As = 'div',
    applyModuleStyling = false,
    ...props
}) {
    const { push } = useRouter()
    const handleClick = React.useCallback(
        event => {
            const { target, metaKey } = event

            if (
                target.tagName === 'A' &&
                metaKey === false &&
                URLUtils.isAvalancheCanada(target.href)
            ) {
                event.preventDefault()

                push(URLUtils.href(target.href))
            }
        },
        [push]
    )

    if (!children) {
        return null
    }

    return (
        <As
            className={applyModuleStyling ? styles.innerHTML : null}
            onClick={handleClick}
            {...props}
            dangerouslySetInnerHTML={{ __html: children }}
        />
    )
}
