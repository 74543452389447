import { create } from 'zustand'

const MixpanelHistoryStore = create(set => ({
    mapHistory: [],
    forecastHistory: [],
    forecastId: null,
    pushMapHistory: mapHistory => {
        set(state => ({ mapHistory: [...state.mapHistory, mapHistory] }))
    },
    setForecastHistory: forecastHistory => {
        set({ forecastHistory })
    },
    pushForecastHistory: forecastHistory => {
        set(state => ({ forecastHistory: [...state.forecastHistory, forecastHistory] }))
    },
    setForecastId: forecastId => {
        set({ forecastId })
    },
}))

export const useMapHistory = () => MixpanelHistoryStore(state => state.mapHistory)
export const usePushMapHistory = () => MixpanelHistoryStore(state => state.pushMapHistory)
export const useForecastHistory = () => MixpanelHistoryStore(state => state.forecastHistory)
export const useSetForecastHistory = () => MixpanelHistoryStore(state => state.setForecastHistory)
export const usePushForecastHistory = () => MixpanelHistoryStore(state => state.pushForecastHistory)
export const useForecastId = () => MixpanelHistoryStore(state => state.forecastId)
export const useSetForecastId = () => MixpanelHistoryStore(state => state.setForecastId)
