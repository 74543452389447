import request from '@avcan/utils/fetch'
import * as URLUtils from '@avcan/utils/url'

export function mapToSizeFactory(width = 100, height = 100) {
    const transform = `c_fill,h_${height},w_${width}`
    const original = `c_fill,h_${600},w_${1000}`
    const RESOURCE_PREFIX = 'https://res.cloudinary.com/avalanche-ca/image/upload'

    return function mapToSize({ public_id }) {
        return {
            original: `${RESOURCE_PREFIX}/${original}/${public_id}.png`,
            thumbnail: `${RESOURCE_PREFIX}/${transform}/${public_id}.png`,
        }
    }
}

export async function getByTag(tag) {
    const path = URLUtils.buildPath(
        process.env.NEXT_PUBLIC_AVCAN_API_URL_LEGACY,
        'vendor/cloudinary/resources/image/tags',
        tag.trim()
    )
    const resources = []
    let next_cursor = null

    do {
        const params = { max_results: 50, next_cursor }
        const url = URLUtils.appendParams(path, params)
        const payload = await request(url)

        resources.push(...payload.resources)

        next_cursor = payload.next_cursor
    } while (next_cursor)

    return resources
}
