import { useRouter } from 'next/router'
import Menu from '@avcan/crystal/components/menu/Menu'
import { findNode } from './utils'
import useMenu from 'constants/menus/avcan'
import { useAuth } from 'contexts/authentication'
import Logo from '@avcan/crystal/assets/logos/AvCan.svg'

export default function AvalancheCanadaNavbar() {
    const { pathname } = useRouter()
    const menu = useMenu()
    const { isAuthenticated } = useAuth()

    return (
        <Menu
            menu={menu}
            logo={Logo.src}
            color="var(--color-blue)"
            activeTab={findNode(menu, pathname)}
            logoHref={process.env.NEXT_PUBLIC_SITE_ORIGIN_URL}
            isLoggedIn={isAuthenticated}
        />
    )
}
