import * as React from 'react'
import { useRouter } from 'next/router'
import { shouldPolyfill as shouldPolyfillCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill'
import { shouldPolyfill as shouldPolyfillIntlLocale } from '@formatjs/intl-locale/should-polyfill'
import { shouldPolyfill as shouldPolyfillIntlPuralRules } from '@formatjs/intl-pluralrules/should-polyfill'
import { shouldPolyfill as shouldPolyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill'
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill'
import { shouldPolyfill as shouldPolyfillDateTimeFormat } from '@formatjs/intl-datetimeformat/should-polyfill'
import { shouldPolyfill as shouldPolyfillDisplayNames } from '@formatjs/intl-displaynames/should-polyfill'
import { shouldPolyfill as shouldPolyfillListFormat } from '@formatjs/intl-listformat/should-polyfill'
import { FR } from '@avcan/constants/locales'
import 'core-js/modules/es.object.from-entries'

export function usePolyfills() {
    const { locale } = useRouter()

    React.useEffect(() => {
        load(locale)
    }, [locale])
}

async function load(locale) {
    if (shouldPolyfillCanonicalLocales()) {
        await import('@formatjs/intl-getcanonicallocales/polyfill')
    }
    if (shouldPolyfillIntlLocale()) {
        await import('@formatjs/intl-locale/polyfill')
    }
    if (shouldPolyfillIntlPuralRules(locale)) {
        await import('@formatjs/intl-pluralrules/polyfill')

        switch (locale) {
            case FR:
                await import('@formatjs/intl-pluralrules/locale-data/fr')
                break
            default:
                await import('@formatjs/intl-pluralrules/locale-data/en')
        }
    }
    if (shouldPolyfillNumberFormat(locale)) {
        await import('@formatjs/intl-numberformat/polyfill')

        switch (locale) {
            case FR:
                await import('@formatjs/intl-numberformat/locale-data/fr')
                break
            default:
                await import('@formatjs/intl-numberformat/locale-data/en')
        }
    }
    if (shouldPolyfillRelativeTimeFormat(locale)) {
        await import('@formatjs/intl-relativetimeformat/polyfill')

        switch (locale) {
            case FR:
                await import('@formatjs/intl-relativetimeformat/locale-data/fr')
                break
            default:
                await import('@formatjs/intl-relativetimeformat/locale-data/en')
        }
    }
    if (shouldPolyfillDateTimeFormat(locale)) {
        await import('@formatjs/intl-datetimeformat/polyfill')
        const dataPolyfills = [import('@formatjs/intl-datetimeformat/add-all-tz')]

        switch (locale) {
            case FR:
                dataPolyfills.push(import('@formatjs/intl-datetimeformat/locale-data/fr'))
                break
            default:
                dataPolyfills.push(import('@formatjs/intl-datetimeformat/locale-data/en'))
        }

        await Promise.all(dataPolyfills)

        if ('__setDefaultTimeZone' in Intl.DateTimeFormat) {
            Intl.DateTimeFormat.__setDefaultTimeZone('America/Vancouver')
        }
    }
    if (shouldPolyfillDisplayNames(locale)) {
        await import('@formatjs/intl-displaynames/polyfill')

        switch (locale) {
            case FR:
                await import('@formatjs/intl-displaynames/locale-data/fr')
                break
            default:
                await import('@formatjs/intl-displaynames/locale-data/en')
        }
    }
    if (shouldPolyfillListFormat(locale)) {
        await import('@formatjs/intl-listformat/polyfill')

        switch (locale) {
            case FR:
                await import('@formatjs/intl-listformat/locale-data/fr')
                break
            default:
                await import('@formatjs/intl-listformat/locale-data/en')
        }
    }
}
