import * as React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import Link from 'next/link'
import Head from 'components/Head'
import { Content, Header, Main, Headline, Aside } from 'components/page'
import { Page } from 'layouts/pages'
import { Metadata, Entry } from 'components/metadata'
import { DateElement, Range } from 'components/time'
import { TagSet, Tag } from 'components/tag'
import { StructuredText } from 'prismic/components/base'
import TagTitle from './TagTitle'
import Sidebar from './Sidebar'
import { feed } from 'router/prismic'
import * as ShareUtils from 'utils/share'

Post.propTypes = {
    document: PropTypes.object.isRequired,
    related: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default function Post({ document, related }) {
    const { type } = document
    const { title } = document.data
    const ogImage = ShareUtils.generatePostOgImageUrl(document)
    const description = ShareUtils.generatePostOgDescription(document)

    return (
        <Page>
            <Head title={title} description={description} ogImage={ogImage} />
            <Header title={title} />
            <Content>
                <Main>
                    <PostMetadata {...document} />
                    <PostContent {...document} />
                </Main>
                <Aside>
                    <Sidebar related={related} type={type} />
                </Aside>
            </Content>
        </Page>
    )
}

// Components
function PostMetadata({ tags, type, data }) {
    const { source, location, hosted_by, start_date, end_date, date = start_date, author } = data
    const hasDateRange = start_date && end_date && Date.parse(start_date) !== Date.parse(end_date)
    const intl = useIntl()

    return (
        <Metadata>
            {date && (
                <Entry
                    term={intl.formatMessage({
                        defaultMessage: 'Date',
                        description: 'Layout feed/Post',
                    })}>
                    {hasDateRange ? (
                        <Range from={start_date} to={end_date} />
                    ) : (
                        <DateElement value={date} />
                    )}
                </Entry>
            )}
            {author?.data && (
                <Entry
                    term={intl.formatMessage({
                        defaultMessage: 'Author',
                        description: 'Layout feed/Post',
                    })}>
                    {author.data.firstName + ' ' + author.data.lastName + ', ' + author.data.title}
                </Entry>
            )}
            {typeof location === 'string' && (
                <Entry
                    term={intl.formatMessage({
                        defaultMessage: 'Location',
                        description: 'Layout feed/Post',
                    })}>
                    {location}
                </Entry>
            )}
            {source && (
                <Entry
                    term={intl.formatMessage({
                        defaultMessage: 'Source',
                        description: 'Layout feed/Post',
                    })}>
                    {source}
                </Entry>
            )}
            {hosted_by && (
                <Entry
                    term={intl.formatMessage({
                        defaultMessage: 'Hosted by',
                        description: 'Layout feed/Post',
                    })}>
                    {hosted_by}
                </Entry>
            )}
            {tags.length > 0 && (
                <Entry
                    term={intl.formatMessage({
                        defaultMessage: 'Tagged under',
                        description: 'Layout feed/Post',
                    })}>
                    <TagSet>
                        {tags.map(tag => (
                            <Tag key={tag}>
                                <Link href={feed.tags(type, tag)}>
                                    <TagTitle value={tag} />
                                </Link>
                            </Tag>
                        ))}
                    </TagSet>
                </Entry>
            )}
        </Metadata>
    )
}

function PostContent({ data }) {
    const { shortlede, body, description } = data

    return (
        <React.Fragment>
            {shortlede && (
                <Headline>
                    <StructuredText value={shortlede} />
                </Headline>
            )}
            <StructuredText value={body || description} />
        </React.Fragment>
    )
}
