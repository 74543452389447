import React from 'react'
import Icon from './Icon'
import COLOR from 'constants/colors'

export default function Menu({ color = COLOR, ...props }) {
    return (
        <Icon {...props}>
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" fill={color} />
        </Icon>
    )
}
