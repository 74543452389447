import { Client, Interceptors } from '../../fetch'
import * as URLUtils from '../../lib/url'
import LOCALE from '@avcan/constants/locales'

export default class SpawClient {
    constructor(base, key) {
        const url = URLUtils.buildPath(base, 'spaw')

        this.client = new Client(url, new Interceptors.CacheKey(), new Interceptors.APIKey(key))
    }
    product(id, locale) {
        return this.get(locale, 'products', id)
    }
    products(locale) {
        return this.get(locale, 'products')
    }
    get(locale = LOCALE, type, idOrDate) {
        return this.client.get([locale, type, idOrDate])
    }
    area(locale = LOCALE) {
        return this.client.get([locale, 'areas'])
    }
    // TODO: connect once API built
    // areaByDate(date, locale = LOCALE) {
    //     return this.client.get([locale, 'areas', date])
    // }
}
