import PropTypes from 'prop-types'
import { useIntl, defineMessages } from 'react-intl'
import { noop } from '@avcan/utils/function'
import { ExpandLess, ExpandMore, Remove } from 'components/icons'
import Button from './Button'
import { SUBTILE } from './kinds'
import { NONE, ASC, DESC } from '@avcan/constants/sortings'

const SORTINGS = [NONE, ASC, DESC]

Sorting.propTypes = {
    sorting: PropTypes.oneOf(SORTINGS).isRequired,
    onChange: PropTypes.func.isRequired,
}

export default function Sorting({ sorting = NONE, onChange = noop }) {
    const intl = useIntl()
    const Icon = IconComponents.get(sorting)
    const message = TitleMessages[sorting]
    const title = intl.formatMessage(message)
    function handleClick() {
        onChange(getNext(sorting))
    }

    return (
        <Button onClick={handleClick} kind={SUBTILE} title={title}>
            <Icon />
        </Button>
    )
}

// Utils components & functions
function getNext(current) {
    return SORTINGS[SORTINGS.indexOf(current.toLowerCase()) + 1] || SORTINGS[0]
}
const IconComponents = new Map([
    [ASC, ExpandLess],
    [DESC, ExpandMore],
    [NONE, Remove],
])

const TitleMessages = defineMessages({
    [ASC]: {
        description: 'Sorting Button component',
        defaultMessage: 'Ascending',
    },
    [DESC]: {
        description: 'Sorting Button component',
        defaultMessage: 'Descending',
    },
    [NONE]: {
        description: 'Sorting Button component',
        defaultMessage: 'No sorting applied',
    },
})
