import PropTypes from 'prop-types'
import * as Variables from '../variables'
import css from './cover.module.css'

Cover.propTypes = {
    space: PropTypes.number,
    minHeight: PropTypes.string,
    noParentPadding: PropTypes.bool,
    as: PropTypes.string,
    children: PropTypes.element,
}

export default function Cover({ space, minHeight, noParentPadding, as: As = 'div', children }) {
    const style = Object.assign(
        Variables.createSpace('padding', space),
        Variables.createSpace('parent-padding', noParentPadding ? null : space),
        Variables.create('min-height', minHeight)
    )

    return (
        <As className={css.cover} style={style}>
            {children}
        </As>
    )
}

export function Main({ as: As = 'div', children }) {
    return <As className={css.centered}>{children}</As>
}
