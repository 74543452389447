export function findNode(node, pathname, firstLevelIndex = null) {
    const { children = [], href } = node

    // To avoid having link to AvCan website always active on the foundation home page
    if (href === '/') {
        return null
    }

    if (pathname.startsWith(href)) {
        return firstLevelIndex
    }

    for (const child of children.filter(isNotFeatured)) {
        const index =
            typeof firstLevelIndex === 'number' ? firstLevelIndex : children.indexOf(child)

        if (typeof findNode(child, pathname, index) === 'number') {
            return index
        }
    }

    return null
}

function isNotFeatured(node) {
    return node.type !== 'featured'
}
