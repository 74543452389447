import PropTypes from 'prop-types'
import Link from 'next/link'
import { defineMessages, FormattedMessage } from 'react-intl'
import Sidebar, { Header, Item } from 'components/sidebar'
import * as Router from 'router/prismic'
import { BLOG, NEWS, EVENT } from '@avcan/prismic/types'

FeedSidebar.propTypes = {
    type: PropTypes.oneOf([BLOG, NEWS, EVENT]).isRequired,
    related: PropTypes.array.isRequired,
}

export default function FeedSidebar({ type, related }) {
    const headerMessage = HeaderMessages[type]

    return (
        <Sidebar share follow>
            {related.length > 0 && (
                <Header>
                    <FormattedMessage {...headerMessage} />
                </Header>
            )}
            {related.map(document => (
                <Item key={document.uid}>
                    <Link href={Router.pathname(document)}>{document.data.title}</Link>
                </Item>
            ))}
        </Sidebar>
    )
}

// Constants
const HeaderMessages = defineMessages({
    [BLOG]: {
        defaultMessage: 'Latest blog posts',
        description: 'Layout feed/Sidebar',
    },
    [NEWS]: {
        defaultMessage: 'Latest news',
        description: 'Layout feed/Sidebar',
    },
    [EVENT]: {
        defaultMessage: 'Upcoming events',
        description: 'Layout feed/Sidebar',
    },
})
