import PropTypes from 'prop-types'
import createClassName from 'clsx'
import Link from 'next/link'
import { FormattedMessage, useIntl } from 'react-intl'
import * as Layouts from '../../layouts'
import * as Controls from '../controls'
import * as Icons from '../icons'
import VisuallyHidden from '../VisuallyHidden'
import * as SOCIAL_URLS from '@avcan/constants/social/urls'
import css from './footer.module.css'

FooterLayout.propTypes = {
    children: PropTypes.node,
}

export default function FooterLayout({ children }) {
    const year = new Date().getFullYear()
    const width = 'var(--grid-width)'
    const padding = 'var(--s0)'

    return (
        <footer className={css.Footer} data-theme-variant="dark">
            <Layouts.Stack space={1}>
                <Layouts.Center maxWidth={width} gutters={padding}>
                    <Layouts.Sidebar right sideWidth="25%" space={3} className={css.Primary}>
                        {children}
                        <Medium />
                    </Layouts.Sidebar>
                </Layouts.Center>
                <Layouts.Bleeder as="hr" full />
                <Layouts.Bleeder width={width} padding={padding}>
                    <Layouts.Cluster>
                        <span>
                            <FormattedMessage
                                defaultMessage="Copyright ©{year}"
                                description="Footer"
                                values={{ year }}
                            />
                        </span>
                        <Link href="/privacy-policy">
                            <FormattedMessage
                                defaultMessage="Privacy Policy"
                                description="Footer"
                            />
                        </Link>
                        <Link href="/terms-of-use">
                            <FormattedMessage defaultMessage="Terms of Use" description="Footer" />
                        </Link>
                        <Link href="/accessibility">
                            <FormattedMessage defaultMessage="Accessibility" description="Footer" />
                        </Link>
                    </Layouts.Cluster>
                </Layouts.Bleeder>
            </Layouts.Stack>
        </footer>
    )
}

Navigation.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.shape({
                header: PropTypes.string.isRequired,
                links: PropTypes.shape({
                    label: PropTypes.string.isRequired,
                    href: PropTypes.string.isRequired,
                }),
            })
        )
    ).isRequired,
}

export function Navigation({ columns }) {
    const space = 1
    const limit = columns.length

    return (
        <Layouts.Switcher as="nav" limit={limit} space={space}>
            {columns.map((sections, index) => (
                <Layouts.Stack key={index} space={space}>
                    {sections.map(({ header, links = [] }, index) => (
                        <div key={index}>
                            <Section header={header}>
                                {links.map(({ href, label }) => (
                                    <Link key={href} href={href}>
                                        {label}
                                    </Link>
                                ))}
                            </Section>
                        </div>
                    ))}
                </Layouts.Stack>
            ))}
        </Layouts.Switcher>
    )
}

Section.propTypes = {
    header: PropTypes.string,
    as: PropTypes.string,
    children: PropTypes.node,
}

// TODO Should use the same component (<SubSection>) as in the menu
function Section({ header, as, children }) {
    return (
        <Layouts.Stack as={as} space={-2}>
            <strong>{header}</strong>
            {children}
        </Layouts.Stack>
    )
}

function Medium() {
    return (
        <Layouts.Stack space={2} className={css.Medium}>
            <Layouts.Stack as={NewsletterSubscriptionForm} />
            <SocialMediaIconSet />
        </Layouts.Stack>
    )
}

function NewsletterSubscriptionForm(props) {
    const intl = useIntl()
    const url = 'https://avalanche.us2.list-manage.com/subscribe'
    const placeholder = intl.formatMessage({
        defaultMessage: 'Your email',
        description: 'Footer',
    })

    return (
        <form action={url} method="post" target="_blank" {...props}>
            <label htmlFor="email" className="h2">
                <FormattedMessage
                    defaultMessage="Stay connected with our newsletter"
                    description="Footer"
                />
            </label>
            <Layouts.Sidebar as={Controls.Wrapper} right space={null}>
                <input
                    id="email"
                    name="EMAIL"
                    type="email"
                    required
                    placeholder={placeholder}
                    autoComplete="email"
                />
                <button type="submit">→</button>
            </Layouts.Sidebar>
            <input name="u" type="hidden" value="865353c8e713ab831d1fde08f" />
            <input name="id" type="hidden" value="8083bbbc7a" />
        </form>
    )
}

function SocialMediaIconSet() {
    const className = createClassName(css.IconSet, css.SocalMediaIconSet)

    return (
        <Layouts.Cluster as="nav" className={className}>
            <Link href={SOCIAL_URLS.FACEBOOK} target="facebook">
                <Icons.Facebook />
                <VisuallyHidden>Facebook</VisuallyHidden>
            </Link>
            <Link href={SOCIAL_URLS.INSTAGRAM} target="instagram">
                <Icons.Instagram />
                <VisuallyHidden>Instagram</VisuallyHidden>
            </Link>
            <Link href={SOCIAL_URLS.TWITTER} target="twitter">
                <Icons.Twitter />
                <VisuallyHidden>Twitter</VisuallyHidden>
            </Link>
        </Layouts.Cluster>
    )
}
