import * as React from 'react'
import { useIntl } from 'react-intl'
import { MONTH } from '@avcan/constants/intl/formats'
import { range } from '@avcan/utils/array'

export function useIntlMemo(compute) {
    const intl = useIntl()

    return React.useMemo(() => compute(intl), [intl])
}

export function useMessageMap(messages) {
    return useIntlMemo(
        intl =>
            new Map(
                Object.entries(messages).map(([key, message]) => [key, intl.formatMessage(message)])
            )
    )
}

export function useMonths() {
    return useIntlMemo(intl =>
        range(12).map(month => intl.formatDate(new Date(1999, month, 9), MONTH))
    )
}

export function useAspects() {
    const { locale } = useIntl()

    return locale.startsWith('fr') ? FR_ASPECTS : ASPECTS
}

// Constants and utils
const ASPECTS = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW']
const FR_ASPECTS = ['N', 'NE', 'E', 'SE', 'S', 'SO', 'O', 'NO']
