import { ACCESS_TOKEN, API } from './config'
import * as URLUtils from '@avcan/utils/url'

export function place(term) {
    term = encodeURIComponent(term.trim())

    const path = URLUtils.buildPath(API, 'geocoding/v5/mapbox.places', term + '.json')

    return URLUtils.appendParams(path, PLACE_PARAMS)
}

// Constants
const PLACE_PARAMS = {
    country: 'ca,us,au,jp',
    types: 'country,region,locality,place',
    autocomplete: true,
    access_token: ACCESS_TOKEN,
}
