import React from 'react'
import { useIntl } from 'react-intl'
import weatherStation from './weather-station.svg'

export default function WeatherStation({ width = 24, height = 24, ...props }) {
    const intl = useIntl()
    const alt = intl.formatMessage({
        defaultMessage: 'Weather station icon',
    })

    return <img width={width} height={height} alt={alt} {...props} src={weatherStation.src} />
}
