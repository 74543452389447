import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as Variables from '../variables'
import css from './sidebar.module.css'

Sidebar.propTypes = {
    space: PropTypes.number,
    sideWidth: PropTypes.number,
    contentWidth: PropTypes.number,
    right: PropTypes.bool,
    as: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
}

export default function Sidebar({
    space,
    sideWidth,
    contentWidth,
    right = false,
    as: As = 'div',
    children,
    ...props
}) {
    const style = Object.assign(
        Variables.createSpace('sidebar', space),
        Variables.create('side-width', sideWidth),
        Variables.create('content-min-width', contentWidth)
    )
    const className = clsx(css.sidebar, props.className, {
        [css.left]: !right,
        [css.right]: right,
    })

    return (
        <As className={className} style={style}>
            {children}
        </As>
    )
}
