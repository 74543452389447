import PropTypes from 'prop-types'
import Link from 'next/link'
import { pathname } from 'router/prismic'

DocumentLink.propTypes = {
    type: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    children: PropTypes.node,
    data: PropTypes.shape({
        title: PropTypes.string,
    }),
}

export default function DocumentLink({ children, type, uid, data }) {
    const href = pathname({ type, uid })

    return <Link href={href}>{children || data?.title}</Link>
}
