import Prismic from '@prismicio/client'
import EN, { FR } from '@avcan/constants/locales'

export default class Client {
    constructor(api) {
        this.client = Prismic.client(api)
    }
    async query(predicates, { locale = EN, ...options } = {}) {
        if (locale === FR || locale === '*') {
            // Not all Prismic documents have been translated, so
            // we are loading documents for all languages and filter
            // to the language afterward. In case a given document has not
            // been translated, the English one will be returned
            options.lang = '*'
        }

        const payload = await this.client.query(predicates, options)

        if (locale === EN || locale === '*') {
            return payload
        }

        return {
            ...payload,
            results: payload.results.filter(({ lang, alternate_languages }) => {
                const language = LANGUAGES.get(locale)

                if (lang === language) {
                    return true
                }

                return alternate_languages.length === 0 // Meanning this is the only document available, so it needs to be includes!
            }),
        }
    }
    async queryFirst(predicates, options) {
        const { results } = await this.query(predicates, options)

        return results[0] || null
    }
}

// Constants
const LANGUAGES = new Map([
    [EN, 'en-us'],
    [FR, 'fr-ca'],
])
