import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as Variables from '../variables'
import css from './drawer.module.css'

const Drawer = React.forwardRef((props, ref) => {
    const { contain, margin, fixed, position = 'left', as: As = 'div', children, className } = props
    const style = Variables.create('margin', margin)
    const classNames = clsx(css.drawer, css[position], className, {
        [css.fixed]: fixed,
        [css.contain]: contain,
    })

    return (
        <As ref={ref} className={classNames} style={style}>
            {children}
        </As>
    )
})

Drawer.propTypes = {
    contain: PropTypes.bool,
    margin: PropTypes.string,
    fixed: PropTypes.bool,
    position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
    className: PropTypes.string,
    as: PropTypes.string,
    children: PropTypes.element,
}

export default Drawer
