import clsx from 'clsx'
import css from './controls.module.css'

export function Wrapper({ children, className, style }) {
    return (
        <div className={clsx(className, css.Wrapper)} style={style}>
            {children}
        </div>
    )
}
