import { useIntlMemo } from 'hooks/intl'

export default function useColumns() {
    return useIntlMemo(intl => {
        return [
            [
                {
                    header: intl.formatMessage({
                        defaultMessage: 'My account',
                        description: 'Page footer',
                    }),
                    links: [
                        {
                            href: '/account',
                            label: intl.formatMessage({
                                defaultMessage: 'Login or Register',
                                description: 'Page footer',
                            }),
                        },
                        // {
                        //     href: '/register',
                        //     label: intl.formatMessage({
                        //         defaultMessage: 'Register',
                        //         description: 'Page footer',
                        //     }),
                        // },
                    ],
                },
                {
                    header: intl.formatMessage({
                        defaultMessage: 'Connect',
                        description: 'Page footer',
                    }),
                    links: [
                        // {
                        //     href: 'tel:250-837-2141',
                        //     label: '250-837-2141',
                        // },
                        {
                            href: 'mailto:info@avalanche.ca',
                            label: 'info@avalanche.ca',
                        },
                        {
                            href: '/about/contact-us',
                            label: intl.formatMessage({
                                defaultMessage: 'Contact us',
                                description: 'Page footer',
                            }),
                        },
                    ],
                },
                {
                    header: intl.formatMessage({
                        defaultMessage: 'Software info',
                        description: 'Page footer',
                    }),
                    links: [
                        {
                            href: 'https://avalanche-canada.notion.site/AvCan-Website-f401d73290164115af1d75f0f0d05554',
                            label: intl.formatMessage({
                                defaultMessage: 'Website changelog',
                                description: 'Page footer',
                            }),
                        },
                        {
                            href: 'https://avalanche-canada.notion.site/AvCan-App-09f4b10c57834ffd90170005dd403910',
                            label: intl.formatMessage({
                                defaultMessage: 'Mobile app changelog',
                                description: 'Page footer',
                            }),
                        },
                        {
                            href: 'https://docs.avalanche.ca',
                            label: intl.formatMessage({
                                defaultMessage: 'Avalanche Canada API',
                                description: 'Page footer',
                            }),
                        },
                    ],
                },
            ],
            [
                {
                    header: intl.formatMessage({
                        defaultMessage: 'About us',
                        description: 'Page footer',
                    }),
                    links: [
                        {
                            href: '/about',
                            label: intl.formatMessage({
                                defaultMessage: 'Overview',
                                description: 'Page footer',
                            }),
                        },
                        {
                            href: '/about/annual-reports',
                            label: intl.formatMessage({
                                defaultMessage: 'Annual Reports',
                                description: 'Page footer',
                            }),
                        },
                        {
                            href: '/about/the-aspect',
                            label: intl.formatMessage({
                                defaultMessage: 'The Aspect',
                                description: 'Page footer',
                            }),
                        },
                        {
                            href: '/about/avcan-staff',
                            label: intl.formatMessage({
                                defaultMessage: 'Staff',
                                description: 'Page footer',
                            }),
                        },
                        {
                            href: '/about/board-of-directors',
                            label: intl.formatMessage({
                                defaultMessage: 'Board of Directors',
                                description: 'Page footer',
                            }),
                        },
                        {
                            href: '/about/service-award',
                            label: intl.formatMessage({
                                defaultMessage: 'Gordon Ritchie Service Award',
                                description: 'Page footer',
                            }),
                        },
                    ],
                },
            ],
            [
                {
                    header: intl.formatMessage({
                        defaultMessage: 'Funders',
                        description: 'Page footer',
                    }),
                    links: [
                        {
                            href: '/collaborators#government-partners',
                            label: intl.formatMessage({
                                defaultMessage: 'Government Partners',
                                description: 'Page footer',
                            }),
                        },
                        {
                            href: '/sponsors',
                            label: intl.formatMessage({
                                defaultMessage: 'Sponsors',
                                description: 'Page footer',
                            }),
                        },
                        {
                            href: '/collaborators',
                            label: intl.formatMessage({
                                defaultMessage: 'Collaborators',
                                description: 'Page footer',
                            }),
                        },
                    ],
                },
                {
                    header: intl.formatMessage({
                        defaultMessage: 'Work with us',
                        description: 'Page footer',
                    }),
                    links: [
                        {
                            href: '/careers',
                            label: intl.formatMessage({
                                defaultMessage: 'Careers',
                                description: 'Page footer',
                            }),
                        },
                        {
                            href: '/instructing-ast',
                            label: intl.formatMessage({
                                defaultMessage: 'Become an Avalanche Canada Training Instructor',
                                description: 'Page footer',
                            }),
                        },
                    ],
                },
            ],
        ]
    })
}
