import Prismic from '@prismicio/client'
import * as Utils from './'

const { Predicates } = Prismic

export function id(id) {
    return Predicates.at('document.id', id)
}

export function ids(ids) {
    return Predicates.in('document.id', Array.from(ids))
}

export function uid(type, uid) {
    return field(type, 'uid', uid)
}

export function type(type) {
    return Predicates.at('document.type', type)
}

export function field(type, field, value) {
    return Predicates.at(my(type, field), value)
}

export function tags(tags) {
    if (typeof tags === 'string') {
        tags = [tags]
    }

    return Predicates.at('document.tags', Array.from(tags))
}

export function my(type, field) {
    return `my.${type}.${field}`
}

export function rangeForType(type, start = 'start', end = 'end') {
    return rangePredicates(my(type, start), my(type, end))
}

// Internals
function rangePredicates(start, end, date = new Date()) {
    // Sets the applicable date range
    // To test with expired content, change the 'from' date to a date in the future so that the content hasn't expired yet
    // and change the 'to' date to a date in the past so that the content has "been created" by that date
    const time = date.getTime()
    const from = new Date(time)
    const to = new Date(time)

    from.setDate(from.getDate() + 1)
    to.setDate(to.getDate() - 1)

    return [
        Predicates.dateBefore(start, Utils.formatToDateString(from)),
        Predicates.dateAfter(end, Utils.formatToDateString(to)),
    ]
}
