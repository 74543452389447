import PropTypes from 'prop-types'
import * as Variables from '../variables'
import clsx from 'clsx'
import css from './icon.module.css'

Icon.propTypes = {
    children: PropTypes.element,
}

export default function Icon({ children, ...props }) {
    return (
        <svg
            viewBox="0 0 10 10"
            stroke="currentColor"
            fill="currentColor"
            {...props}
            className={css.icon}>
            {children}
        </svg>
    )
}

With.propTypes = {
    space: PropTypes.number,
    as: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.element,
}

export function With({ space, as: As = 'span', children, className, ...props }) {
    const style = Variables.createSpace('icon', space)

    return (
        <As className={clsx(css.with, className)} style={style} {...props}>
            {children}
        </As>
    )
}
