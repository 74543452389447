import * as Utils from './utils'
import { SPONSOR } from '@avcan/prismic/types'

export function getAll(locale) {
    const options = { ...OPTIONS, locale }

    return Utils.getAllDocuments(SPONSOR, options)
}

const OPTIONS = {
    fetch: [SPONSOR + '.name', SPONSOR + '.url', SPONSOR + '.image-229'],
}
