import PropTypes from 'prop-types'
import clsx from 'clsx'
import styles from './Controls.module.css'

Toggle.propTypes = {
    className: PropTypes.string,
}

export default function Toggle({ name, value, onChange, label }) {
    const className = clsx(styles.Toggle, value && styles.ToggleActive)
    function handleChange(event) {
        onChange(event.target.checked)
    }

    return (
        <label className={className}>
            <input name={name} type="checkbox" checked={value} onChange={handleChange} />
            {label}
        </label>
    )
}
