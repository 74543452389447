import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as Variables from '../variables'
import css from './stack.module.css'

Stack.propTypes = {
    recursive: PropTypes.bool,
    space: PropTypes.number,
    as: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
}

export default function Stack({ recursive, space, as: As = 'div', className, children }) {
    const style = Variables.createSpace('stack', space)
    const elementClassName = clsx(recursive ? css.recursive : css.stack, className)

    return (
        <As className={elementClassName} style={style}>
            {children}
        </As>
    )
}
