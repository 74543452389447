import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as Variables from '../variables'
import css from './imposter.module.css'

Imposter.propTypes = {
    contain: PropTypes.bool,
    margin: PropTypes.string,
    fixed: PropTypes.bool,
    as: PropTypes.string,
    children: PropTypes.element,
}

export default function Imposter({ contain, margin, fixed, as: As = 'div', children }) {
    const style = Variables.create('margin', margin)
    const className = clsx(css.imposter, {
        [css.fixed]: fixed,
        [css.contain]: contain,
    })

    return (
        <As className={className} style={style}>
            {children}
        </As>
    )
}
