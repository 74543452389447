import * as React from 'react'
import PropTypes from 'prop-types'
import * as Variables from '../variables'
import css from './box.module.css'

// Theme colors have not been implemented as well as the invert property

Box.propTypes = {
    padding: PropTypes.number,
    borderWidth: PropTypes.string,
    as: PropTypes.string,
    children: PropTypes.element,
}

export default function Box({ padding, borderWidth, as: As = 'div', children }) {
    const elementRef = React.useRef()
    const style = Object.assign(
        Variables.createSpace('padding', padding),
        Variables.create('border-width', borderWidth)
    )

    React.useLayoutEffect(() => {
        const element = elementRef.current
        const styles = window.getComputedStyle(element)
        const borderWidth = styles.getPropertyValue('--border-width')

        if (borderWidth) {
            element.dataset.border = ''
        } else {
            delete element.dataset.border
        }
    }, [borderWidth])

    return (
        <As ref={elementRef} className={css.box} style={style}>
            {children}
        </As>
    )
}
