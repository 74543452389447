export function generatePostOgDescription(post) {
    return post.data.shortlede?.[0]?.text
}

export function generateMINOgImageUrl(submission) {
    return submission?.images?.[0]?.url.replace('{size}', '1200')
}

export function generatePostOgImageUrl(post) {
    let ogImageUrl = ''
    switch (post.type) {
        case 'blog':
            ogImageUrl = post.data.preview_image.url
            break
        case 'event':
            ogImageUrl = post.data.featured_image.url
            break
        case 'news':
            ogImageUrl = post.data.featured_image.url
            break
        default:
            break
    }

    return ogImageUrl
}

export function generateMWFOgImageUrl(forecast) {
    return forecast?.['day-1-more']?.[0]?.value.find(value => value.type === 'image')?.url
    // TODO Is this too brittle?
}
