import * as URLUtils from '@avcan/utils/url'
import * as Types from '@avcan/prismic/types'
import { ICE_CLIMB_SLUGS } from '@avcan/constants/products/ice-climbs'

export const feed = {
    uid(type, uid) {
        return URLUtils.buildPath(feed.type(type), uid)
    },
    tags(type, tags) {
        return URLUtils.appendParams(feed.type(type), { tags })
    },
    type(type) {
        return FEED_PATHS_BY_TYPE.get(type)
    },
}

export function pathname({ type, uid }) {
    switch (type) {
        case Types.EVENT:
        case Types.BLOG:
        case Types.NEWS:
            return feed.uid(type, uid)
        case Types.DEFINITION:
            return `/glossary#${uid}`
        case Types.FATAL_ACCIDENT:
            return `/map?panel=fatal-accidents/${uid}`
        case Types.STATIC_PAGE:
            const redirects = REDIRECTS.get(Types.STATIC_PAGE)

            if (redirects.has(uid)) {
                return redirects.get(uid)
            } else {
                return URLUtils.buildPath('/pages', uid)
            }
        default:
            return '/404'
    }
}

// Constants
const FEED_PATHS_BY_TYPE = new Map([
    [Types.NEWS, '/news'],
    [Types.BLOG, '/blogs'],
    [Types.EVENT, '/events'],
])
const ICE_CLIMB_REDIRECTS = ICE_CLIMB_SLUGS.map(slug => {
    return [slug, `/resources/ice-climbing/atlas/${slug}`]
})
const REDIRECTS = new Map([
    [
        Types.STATIC_PAGE,
        new Map([
            ['mountain-information-network-faq', '/mountain-information-network/faq'],
            ['mountain-information-network-overview', '/mountain-information-network'],
            ['mountain-information-network-acronyms', '/mountain-information-network/acronyms'],
            [
                'mountain-information-network-submission-guidelines',
                '/mountain-information-network/submission-guidelines',
            ],
            ['ambassadors', '/ambassadors'],
            ['start-here', '/start-here'],
            ['training', '/training'],
            ['education', '/education'],
            ['webinar-recordings', '/education/recorded-webinars'],
            ['decision-making', '/decision-making'],
            ['essential-gear', '/gear'],
            ['sponsors', '/sponsors'],
            ['ice-climbing', '/resources/ice-climbing'],
            ['snowshoeing', '/resources/snowshoeing'],
            ['skiing-and-snowboarding', '/resources/skiing-and-snowboarding'],
            ['mountain-snowmobiling', '/resources/mountain-snowmobiling'],
            ['ice-climbing-atlas', '/resources/ice-climbing/atlas'],
            ['about', '/about'],
            ['contact-us', '/about/contact-us'],
            ['annual-reports', '/about/annual-reports'],
            ['the-aspect', '/about/the-aspect'],
            ['avcan-staff', '/about/avcan-staff'],
            ['board-of-directors', '/about/board-of-directors'],
            ['service-award', '/about/service-award'],
            ...ICE_CLIMB_REDIRECTS,
        ]),
    ],
])
