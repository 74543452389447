import React from 'react'
import { useIntl } from 'react-intl'
import { useRouter } from 'next/router'

export default function ContactForm() {
    const { locale } = useRouter()
    const src = `https://avalanchecanada.supportbee.com/web_tickets/new?embed=true&locale=${locale}`
    const intl = useIntl()
    const title = intl.formatMessage({
        defaultMessage: 'Avalanche Canada — Contact Form',
    })

    return <iframe title={title} src={src} />
}
