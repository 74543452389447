export function create(name, value) {
    if (value) {
        return {
            ['--' + name]: value,
        }
    }

    return {}
}

export function createSpace(name, space) {
    if (space === null) {
        return create(name + '-space', '0px')
    }

    if (SPACES.has(space)) {
        return create(name + '-space', `var(--s${space})`)
    }

    return {}
}

// Constants
const SPACES = new Set([-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5])
