import * as React from 'react'
import Link from 'next/link'
import Head from 'components/Head'
import { useRouter } from 'next/router'
import { FormattedMessage, useIntl } from 'react-intl'
import { Content, Header, Main } from 'components/page'
import { Page } from 'layouts/pages'
import * as Texts from 'components/text'
import Shim from 'components/Shim'
import Pagination from 'components/pagination'
import EntrySet from './EntrySet'
import Entry from './Entry'
import { FeedTextMessages } from 'constants/prismic'
import * as Context from './context'
import * as Controls from 'components/form'
import * as ObjectUtils from '@avcan/utils/object'
import { GRAY_LIGHTEST } from 'constants/colors'

export { default as Splash } from './Splash'
export { default as Post } from './Post'

export function Layout({ title, form }) {
    return (
        <Page>
            <Head title={title} description={title} />
            <Header title={title} />
            <Content>
                <Main>
                    {form}
                    <FeedContent />
                </Main>
            </Content>
        </Page>
    )
}

export function ControlSet({ children }) {
    return (
        <Controls.ControlSet horizontal>
            {React.Children.map(children, child =>
                React.cloneElement(child, {
                    style: CONTROL_STYLE,
                })
            )}
        </Controls.ControlSet>
    )
}

// Components
function FeedContent() {
    const { posts, error, page, totalPages, onPageChange } = Context.useContext()

    return (
        <Shim vertical>
            {!posts && !error && <Texts.Loading />}
            {Array.isArray(posts) &&
                (posts.length === 0 ? (
                    <NoPost />
                ) : (
                    <React.Fragment>
                        <EntrySet>
                            {posts.map(post => (
                                <Entry key={post.uid} {...post} />
                            ))}
                        </EntrySet>
                        <Pagination active={page} onChange={onPageChange} total={totalPages} />
                    </React.Fragment>
                ))}
        </Shim>
    )
}

function NoPost() {
    const { type } = Context.useContext()
    const { query } = useRouter()
    const intl = useIntl()
    const text = intl.formatMessage(FeedTextMessages[type]).toLowerCase()
    function link(text) {
        return (
            <Link href="" scroll={false}>
                {text}
            </Link>
        )
    }

    return (
        <React.Fragment>
            <Texts.Muted>
                <FormattedMessage
                    description="Layout feed/Feed"
                    defaultMessage="No {text} has been found."
                    values={{ text }}
                />
            </Texts.Muted>
            {ObjectUtils.isEmpty(query) || (
                <Texts.Muted>
                    <FormattedMessage
                        description="Layout feed/Feed"
                        defaultMessage="You can <link>reset your criteria</link> to find more articles."
                        values={{ link }}
                    />
                </Texts.Muted>
            )}
        </React.Fragment>
    )
}

// Styles
const CONTROL_STYLE = {
    borderBottom: '2px solid ' + GRAY_LIGHTEST,
    margin: 0,
    padding: '0.5em',
}
