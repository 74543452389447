import React from 'react'

import { useIntl } from 'react-intl'

import spaw24 from './spaw-24.svg'

export default function SPAWIcon({ width = 24, height = 24, ...props }) {
    const intl = useIntl()
    const alt = intl.formatMessage({
        defaultMessage: 'Closure Zone icon',
    })

    return <img width={width} height={height} alt={alt} {...props} src={spaw24.src} />
}
