import PropTypes from 'prop-types'
import * as Variables from '../variables'
import css from './switcher.module.css'

Switcher.propTypes = {
    threshold: PropTypes.string,
    space: PropTypes.number,
    limit: PropTypes.number,
    as: PropTypes.string,
    children: PropTypes.element,
}

export default function Switcher({ threshold, space, limit = 4, as: As = 'div', children }) {
    const style = Object.assign(
        Variables.createSpace('switcher', space),
        Variables.create('threshold', threshold)
    )

    return (
        <As className={css.switcher} style={style} data-limit={limit}>
            {children}
        </As>
    )
}
