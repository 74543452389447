import * as React from 'react'

// Wrap your app in this component to ensure addFlexGapPolyfillClass() gets called
export default function Crystal({ children }) {
    React.useEffect(() => {
        addFlexGapPolyfillClass()
    }, [])

    return children
}

function addFlexGapPolyfillClass() {
    document.documentElement.classList.toggle('no-flexbox-gap', !isFlexGapSupported())
}

// Via: https://ishadeed.com/article/flexbox-gap/. Using JS here instead of a CSS-based fix given how hard it is to detect `display: flex` and `gap` together (https://every-layout.dev/layouts/cluster/#graceful-degradation)
function isFlexGapSupported() {
    // create flex container with row-gap set
    var flex = document.createElement('div')
    flex.style.display = 'flex'
    flex.style.flexDirection = 'column'
    flex.style.rowGap = '1px'

    // create two, elements inside it
    flex.appendChild(document.createElement('div'))
    flex.appendChild(document.createElement('div'))

    // append to the DOM (needed to obtain scrollHeight)
    document.body.appendChild(flex)
    var isSupported = flex.scrollHeight === 1 // flex container should be 1px high from the row-gap
    flex.parentNode.removeChild(flex)

    return isSupported
}
