import useSWR from 'swr'

import { useRouter } from 'next/router'

import { get } from '@avcan/prismic/clients/highlight'

export function useHighlight() {
    const { locale } = useRouter()
    const key = ['highlight', locale]

    return useSWR(key, () => get(locale))
}
