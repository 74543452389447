import * as Misc from 'components/misc'
import { useSponsor } from 'hooks/useSponsors'

export default function Sponsor({ label, payload = null }) {
    const sponsor = useSponsor(payload?.product)

    if (!sponsor) {
        return null
    }

    const { name, logo, url } = sponsor

    return <Misc.Sponsor label={label} name={name} logo={logo} url={url} />
}
