import * as React from 'react'

import Link from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { FormattedMessage } from 'react-intl'

import * as KeyCodes from '@avcan/constants/keycodes'
import { LOCALES } from '@avcan/constants/locales'
import { Arrow } from '../icons'
import * as URLUtils from '@avcan/utils/url'

import * as Layouts from '../../layouts'
import * as Hooks from '../../hooks'
import VisuallyHidden from '../VisuallyHidden'

import css from './menu.module.css'

const MainMenu = React.forwardRef((props, ref) => {
    const { menu, color, logoHref, logo, activeTab, isLoggedIn } = props
    const router = useRouter()
    const [menuOpen, open, close, toggle] = Hooks.useBoolean(false)
    const [activeDrawer, setActiveDrawer, closeDrawer, toggleDrawer] = Hooks.useValue(null)
    const navElementRef = React.useRef()
    const className = clsx(css.Nav, {
        [css.opened]: menuOpen,
        [css.closed]: !menuOpen,
    })

    React.useEffect(() => {
        closeDrawer()
    }, [router.asPath])

    React.useEffect(() => {
        function handleKeyUp(event) {
            if (KeyCodes.esc === event.keyCode) {
                closeDrawer()
            }
        }

        function handleClick(event) {
            if (!navElementRef.current.contains(event.target)) {
                closeDrawer()
            }
        }

        document.addEventListener('mousedown', handleClick)
        document.addEventListener('keyup', handleKeyUp)

        return () => {
            document.removeEventListener('mousedown', handleClick)
            document.removeEventListener('keyup', handleKeyUp)
        }
    }, [])

    React.useEffect(() => {
        document.body.classList.toggle(css.StopScroll, menuOpen)
    }, [menuOpen])

    return (
        <nav ref={navElementRef} className={className}>
            <Brand logo={logo} logoHref={logoHref} color={color}>
                <div className={css.BurgerContainer}>
                    <button className={css.Burger} onClick={toggle}>
                        <VisuallyHidden>
                            <FormattedMessage defaultMessage="Menu" description="Navbar burger" />
                        </VisuallyHidden>
                    </button>
                </div>
            </Brand>
            <SettingsMenu isLoggedIn={isLoggedIn} />
            <MainNavigation
                activeTab={activeTab}
                menu={menu}
                activeDrawer={activeDrawer}
                toggleDrawer={toggleDrawer}
            />
        </nav>
    )
})

MainMenu.propTypes = {
    menu: PropTypes.array.isRequired,
    color: PropTypes.string.isRequired,
    logoHref: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    activeTab: PropTypes.number,
    isLoggedIn: PropTypes.bool,
}

export default MainMenu

Brand.propTypes = {
    logo: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    logoHref: PropTypes.string.isRequired,
    children: PropTypes.element,
}

function Brand({ logo, color, logoHref, children }) {
    return (
        <div className={css.Brand} style={{ background: color }}>
            <Link href={logoHref}>
                <img src={logo} alt="Avalanche Canada" />
            </Link>
            {children}
        </div>
    )
}

MainNavigation.propTypes = {
    menu: PropTypes.array.isRequired,
    activeTab: PropTypes.number,
    activeDrawer: PropTypes.number,
    toggleDrawer: PropTypes.func.isRequired,
}

function MainNavigation({ menu, activeTab, activeDrawer, toggleDrawer }) {
    return (
        <div className={css.MainNavigation}>
            {menu.children.map((item, index) => (
                <MainItem
                    key={item.title}
                    index={index}
                    header={item.title}
                    active={activeTab === index}
                    expanded={activeDrawer === index}
                    headerHref={item.href}
                    toggleDrawer={toggleDrawer}>
                    {item.children && activeDrawer === index && <Drawer sections={item.children} />}
                </MainItem>
            ))}
        </div>
    )
}

Drawer.propTypes = {
    sections: PropTypes.array.isRequired,
}

function Drawer({ sections }) {
    const regularLinks = sections.filter(child => child.type !== 'featured')
    const featuredLinks = sections.filter(child => child.type === 'featured')

    return (
        <Layouts.Center maxWidth="var(--max-page-width)" gutters="0">
            <Layouts.Sidebar right contentWidth={450} className={css.Sidebar}>
                <SubSectionSet sections={regularLinks} />
                <FeaturedLinkList items={featuredLinks} />
            </Layouts.Sidebar>
        </Layouts.Center>
    )
}

FeaturedLinkList.propTypes = {
    items: PropTypes.array.isRequired,
}

function FeaturedLinkList({ items }) {
    if (items.length === 0) {
        return null
    }

    return (
        <SubSection>
            <Layouts.Stack space={3}>
                {items.map(({ title, href, description }) => (
                    <FeaturedLink key={title} title={title} description={description} href={href} />
                ))}
            </Layouts.Stack>
        </SubSection>
    )
}

SubSectionSet.propTypes = {
    sections: PropTypes.array.isRequired,
}

function SubSectionSet({ sections }) {
    return (
        <Layouts.Grid minimum="290px" space={1}>
            {sections &&
                sections.map((item, index) => (
                    <SubSection key={index} header={item.title} headerHref={item.href}>
                        {item.children &&
                            item.children.map(child => (
                                <InternalOrExternalLink
                                    key={child.title}
                                    href={child.href}
                                    title={child.title}
                                />
                            ))}
                    </SubSection>
                ))}
        </Layouts.Grid>
    )
}

SubSection.propTypes = {
    header: PropTypes.string,
    headerHref: PropTypes.string,
    children: PropTypes.node,
}

function SubSection({ header, headerHref, children }) {
    return (
        <Layouts.Stack space={-5}>
            {header && (
                <header className={css.SubHeader}>
                    {headerHref ? <Link href={headerHref}>{header}</Link> : header}
                </header>
            )}
            {children}
        </Layouts.Stack>
    )
}

FeaturedLink.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
}

function FeaturedLink({ title, description, href }) {
    return (
        <Layouts.Box padding={null}>
            {/* TODO remove box and fix nested <Stacks> */}
            <Layouts.Stack space={1}>
                <header className={css.SubHeader}>{description}</header>
                <InternalOrExternalLink href={href} className="rounded" title={title} />
            </Layouts.Stack>
        </Layouts.Box>
    )
}

SettingsMenu.propTypes = {
    isLoggedIn: PropTypes.bool,
}

function SettingsMenu({ isLoggedIn = false }) {
    const { asPath, locale } = useRouter()
    const loginText = isLoggedIn ? (
        <FormattedMessage defaultMessage="account" description="Navbar login" />
    ) : (
        <FormattedMessage defaultMessage="login" description="Navbar login" />
    )

    return (
        <Layouts.Cluster space={-1} className={css.SettingsMenu}>
            {Array.from(LOCALES, loc => (
                <Link
                    key={loc}
                    href={asPath}
                    locale={loc}
                    className={css.Link}
                    aria-current={loc === locale}>
                    {loc}
                </Link>
            ))}
            {/* TODO make me a button and integrate a proper modal */}
            <Link href="/account">{loginText}</Link>
        </Layouts.Cluster>
    )
}

InternalOrExternalLink.propTypes = {
    title: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    className: PropTypes.string,
}

function InternalOrExternalLink({ title, href, className }) {
    if (URLUtils.isExternal(href)) {
        return (
            <a target="_blank" href={href} rel="nofollow noopener noreferrer" className={className}>
                {title}
            </a>
        )
    } else {
        return (
            <Link href={href} className={className}>
                {title}
            </Link>
        )
    }
}

MainItem.propTypes = {
    header: PropTypes.string.isRequired,
    headerHref: PropTypes.string,
    index: PropTypes.number,
    expanded: PropTypes.bool,
    toggleDrawer: PropTypes.func.isRequired,
    headerHref: PropTypes.string,
    active: PropTypes.bool,
    children: PropTypes.element,
}

function MainItem({ header, index, expanded, toggleDrawer, headerHref, active, children }) {
    // TODO refactor to use other open/close/toggle and review props passed here
    const headerClassName = clsx(css.MainHeader, {
        [css.MainHeaderExpanded]: expanded,
    })
    const arrowClassName = clsx(css.Arrow, {
        [css.ArrowActive]: expanded,
    })
    const drawerClassName = clsx(css.Drawer, {
        'visually-hidden': !expanded,
        [css.DrawerClosed]: !expanded,
    })

    function handleClick(index) {
        if (!headerHref) {
            toggleDrawer(index)
        }
    }

    return (
        <Layouts.Stack space={null} className={css.MainItem}>
            <header className={headerClassName} onClick={() => handleClick(index)}>
                {headerHref ? (
                    <Link href={headerHref} aria-current={active ? 'location' : null}>
                        {header}
                    </Link>
                ) : (
                    <>
                        <span aria-current={active ? 'location' : null}>
                            <button className={css.buttonAppearAsText}>{header}</button>
                        </span>
                        <div className={arrowClassName}>
                            <Arrow />
                        </div>
                    </>
                )}
            </header>
            <div className={drawerClassName}>{children}</div>
        </Layouts.Stack>
    )
}
