import PropTypes from 'prop-types'
import useSWR from 'swr'
import { FormattedMessage } from 'react-intl'
import Gallery from './Gallery'
import * as Cloudinary from 'services/cloudinary'
import * as Texts from 'components/text'

CloudinaryGallery.propTypes = {
    tag: PropTypes.string.isRequired,
}

export default function CloudinaryGallery({ tag }) {
    const resize = Cloudinary.mapToSizeFactory()
    const { data, error } = useSWR('cloudinary::' + tag, () => Cloudinary.getByTag(tag))

    if (error) {
        return (
            <Texts.Error>
                <FormattedMessage
                    defaultMessage="Error occured whilte loading resources."
                    description="Prismic Carousel, Youth page"
                />
            </Texts.Error>
        )
    }

    if (!Array.isArray(data)) {
        return <Texts.Loading />
    }

    return <Gallery items={data.map(resize)} />
}
