import PropTypes from 'prop-types'
import clsx from 'clsx'
import * as Variables from '../variables'
import css from './center.module.css'

Center.propTypes = {
    maxWidth: PropTypes.string,
    alignText: PropTypes.bool,
    intrinsic: PropTypes.bool,
    gutters: PropTypes.string,
    as: PropTypes.string,
    children: PropTypes.element,
}

export default function Center({
    maxWidth,
    alignText,
    intrinsic,
    gutters,
    as: As = 'div',
    children,
}) {
    const style = Object.assign(
        Variables.create('max-width', maxWidth),
        Variables.create('gutters', gutters)
    )
    const className = clsx(css.center, {
        'text-align:center': alignText,
        [css.intrinsic]: intrinsic,
    })

    return (
        <As className={className} style={style}>
            {children}
        </As>
    )
}
