import client from './index'
import * as Predicates from './utils/predicates'
import * as Types from '@avcan/prismic/types'

export function get(locale) {
    // To get the most recent highlight, uncomment the line below and comment the line after it
    // const predicates = Predicates.type(Types.HIGHLIGHT)
    const predicates = Predicates.rangeForType(Types.HIGHLIGHT)

    return client.queryFirst(predicates, { locale })
}
