import * as React from 'react'

export function useBoolean(initial) {
    const [boolean, setBoolean] = React.useState(initial)
    const activate = React.useCallback(() => {
        setBoolean(true)
    }, [])
    const deactivate = React.useCallback(() => {
        setBoolean(false)
    }, [])
    const toggle = React.useCallback(() => {
        setBoolean(boolean => !boolean)
    }, [])
    const reset = React.useCallback(() => {
        setBoolean(initial)
    }, [])

    return [boolean, activate, deactivate, toggle, reset]
}

export function useValue(initial) {
    const [value, setValue] = React.useState(initial)
    const clear = React.useCallback(() => {
        setValue(null)
    }, [])
    const toggle = React.useCallback(index => {
        if (value === index) {
            setValue(null)
        } else {
            setValue(index)
        }
    })
    const reset = React.useCallback(() => {
        setValue(initial)
    }, [])

    return [value, setValue, clear, toggle, reset]
}
