import * as React from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { supported } from 'utils/mapbox'
import { noop } from '@avcan/utils/function'

// From: https://developers.google.com/analytics/devguides/collection/analyticsjs/events
export function handleOutboundSponsorClick(event) {
    navigation('Outbound Sponsor', event.currentTarget.href)
}

useService.propTypes = {
    children: PropTypes.element.isRequired,
}

export function useService() {
    const { asPath } = useRouter()

    React.useEffect(() => {
        ga('set', 'transport', 'beacon')
        ga('set', MAPBOXGL_SUPPORTED, supported().toString())
    }, [])

    React.useEffect(() => {
        ga('send', 'pageview', asPath)
    }, [asPath])
}

export function Script() {
    return (
        <React.Fragment>
            <GoogleAnalytics />
            <FacebookPixel />
        </React.Fragment>
    )
}

function GoogleAnalytics() {
    const script = {
        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALITICS_ID}');
        `,
    }

    return (
        <React.Fragment>
            <script dangerouslySetInnerHTML={script}></script>
            <script async src={process.env.NEXT_PUBLIC_GOOGLE_ANALITICS_SRC}></script>
        </React.Fragment>
    )
}

function FacebookPixel() {
    const id = process.env.NEXT_PUBLIC_FACEBOOK_ANALYTICS_ID
    const script = {
        __html: `
        (function (f, b, e, v, n, t, s) {
            if (f.fbq) return
            n = f.fbq = function () {
            n.callMethod
                ? n.callMethod.apply(n, arguments)
                : n.queue.push(arguments)
            }

            if (!f._fbq) f._fbq = n

            n.push = n
            n.loaded = !0
            n.version = '2.0'
            n.queue = []

            t = b.createElement(e)
            t.async = !0
            t.src = v

            s = b.getElementsByTagName(e)[0]
            s.parentNode.insertBefore(t, s)
        })(
            window,
            document,
            'script',
            'https://connect.facebook.net/en_US/fbevents.js'
        );
        fbq('init', '${id}');
        fbq('track', 'PageView');`,
    }

    return (
        <React.Fragment>
            <script dangerouslySetInnerHTML={script}></script>
            <noscript>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                    height="1"
                    width="1"
                    alt=""
                    src={`https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1`}
                />
            </noscript>
        </React.Fragment>
    )
}

// Utils and constants
// TODO Remove the second noop
const ga = typeof window === 'undefined' ? noop : window.ga || noop
const HEADERS = ['Danger ratings', 'Problems', 'Details']
const MAPBOXGL_SUPPORTED = 'dimension1'
function navigation(...args) {
    ga('send', 'event', 'Navigation', ...args)
}
