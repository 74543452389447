import { defineMessages } from 'react-intl'
import * as Types from '@avcan/prismic/types'

export const FeedTextMessages = defineMessages({
    [Types.BLOG]: {
        description: 'Constants prismic',
        defaultMessage: 'Blog',
    },
    [Types.NEWS]: {
        description: 'Constants prismic',
        defaultMessage: 'News',
    },
    [Types.EVENT]: {
        description: 'Constants prismic',
        defaultMessage: 'Event',
    },
})
