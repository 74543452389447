import Biography from 'components/biography'
import { StructuredText } from 'prismic/components/base'

export default function StaffSet({ value }) {
    return value.map(({ staff }) => {
        const { biography, avatar, ...props } = staff.data

        return (
            <Biography key={staff.id} avatar={avatar?.url} {...props}>
                <StructuredText value={biography} />
            </Biography>
        )
    })
}
