import Image from 'next/image'

import { Credit, InnerHTML } from 'components/misc'

export const SpawImage = ({ image, galleryId }) => {
    return (
        <>
            <a
                href={image.url}
                data-pswp-width={image.width}
                data-pswp-height={image.height}
                key={galleryId + '-' + image.id}
                target="_blank"
                rel="noreferrer">
                <div style={styles.container}>
                    <Image
                        src={image.url}
                        alt={image.tag}
                        width={image.width}
                        height={image.height}
                        loading="lazy"
                        quality={'auto'}
                        sizes="100vw"
                        style={{
                            width: '100%',
                            height: 'auto',
                        }}
                    />
                    {image.credit && (
                        <div style={styles.credit}>
                            <Credit>{image.credit}</Credit>
                        </div>
                    )}
                </div>
            </a>
            <InnerHTML>{image.caption}</InnerHTML>
        </>
    )
}

const styles = {
    container: {
        display: 'block',
        width: '100%',
        position: 'relative',
        marginBottom: '1rem',
    },
    credit: {
        position: 'absolute',
        bottom: 0,
        right: 0,
    },
}
